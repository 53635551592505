import { Suspense, useEffect } from 'react'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'
import { HiLogout } from 'react-icons/hi'
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay'
import { toast } from 'react-toastify'

import { useAuthActions, useUser } from 'src/auth'
import browserLogger from 'src/browserLogger'
import { LoadingPagePlaceholder } from 'src/components/LoadingPagePlaceholder'
import { Constants } from 'src/constants'

import { EmailNotVerifiedPageSendEmailVerificationLinkMutation } from './__generated__/EmailNotVerifiedPageSendEmailVerificationLinkMutation.graphql'
import { EmailNotVerifiedPageUserQuery } from './__generated__/EmailNotVerifiedPageUserQuery.graphql'

const EmailNotVerifiedPage = () => {
  const { logOut } = useAuthActions()

  const data = useLazyLoadQuery<EmailNotVerifiedPageUserQuery>(
    graphql`
      query EmailNotVerifiedPageUserQuery {
        user {
          emailVerified
        }
      }
    `,
    {}
  )

  const user = useUser()

  useEffect(() => {
    if (!user) {
      navigate(routes.login())
      return
    }
    if (data && data.user && data.user.emailVerified) {
      navigate(routes.home())
      return
    }
  }, [user, data])

  const [sendEmailVerification] =
    useMutation<EmailNotVerifiedPageSendEmailVerificationLinkMutation>(graphql`
      mutation EmailNotVerifiedPageSendEmailVerificationLinkMutation {
        sendEmailVerificationLink
      }
    `)

  return (
    <Suspense fallback={<LoadingPagePlaceholder />}>
      <Metadata
        title="Verify Email"
        description="Verify your email to continue"
      />
      <header className="flex h-12 w-full flex-row md:h-16">
        <div className="mx-auto flex h-full w-full max-w-7xl items-center justify-between px-4 font-bricolage-semibold text-2xl dark:text-white">
          <p
            onClick={() => {
              navigate(routes.home())
            }}
            className="cursor-pointer"
          >
            Briefcase
          </p>
        </div>
        <li
          className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group cursor-pointer"
          onClick={logOut}
        >
          <HiLogout className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
          <span className="flex-1 ms-3 whitespace-nowrap">Log out</span>
        </li>
      </header>
      <main>
        <div className="flex flex-col items-center justify-center p-4">
          <div className="max-w-md w-full space-y-8 text-center">
            <h1 className="text-xl font-bold">Check Your Email</h1>
            <p className="text-gray-500">
              We&apos;ve sent a verification link to your email address. Please
              click the link in the e-mail to verify your account.
            </p>
            <p className="text-sm text-gray-600 mt-4">
              Didn&apos;t receive the email?{' '}
              <button
                className="text-blue-600 hover:text-blue-800"
                onClick={() => {
                  sendEmailVerification({
                    variables: {},
                    onCompleted(_, errors) {
                      if (errors) {
                        browserLogger.error(
                          'Error sending email confirmation',
                          { errors }
                        )
                        toast.error('Error sending your email confirmation', {
                          autoClose: Constants.DEFAULT_TOAST_CLOSE,
                        })
                        return
                      } else {
                        toast.success('Email confirmation sent', {
                          autoClose: Constants.DEFAULT_TOAST_CLOSE,
                        })
                        return
                      }
                    },
                  })
                }}
              >
                Resend verification email
              </button>
            </p>
          </div>
        </div>
      </main>
    </Suspense>
  )
}

export default EmailNotVerifiedPage
